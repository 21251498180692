<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div class="position-relative bg-img-hero space-top-2 space-top-lg-3" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <!-- Tagline -->
      <div class="container mt-5 mt-lg-9" data-aos="fade">
        <div class="row">
          <div class="col-lg-7 col-xl-7">
            <div class="mb-5 w-100 w-md-75 w-lg-100 mx-auto text-center text-lg-left">
              <h1 class="display-4 mb-3">Focus on Values, <br class="d-lg-block">Not Redundancies</h1>
              <div class="d-none d-md-block mb-6">
                <p class="lead">Harness the Power of beNovelty's Cutting-Edge<br><span class="font-weight-bold" style="font-size: 24px;">API-First</span> and Intelligent <span class="font-weight-bold" style="font-size: 24px;">Agentic AI</span> Solutions</p>
              </div>
              <div class="w-85 mx-auto d-md-none">
                <p>Harness the Power of beNovelty's Cutting-Edge<br><span class="font-weight-bold" style="font-size: 20px;">API-First</span> and Intelligent <span class="font-weight-bold" style="font-size: 20px;">Agentic AI</span> Solutions</p>
              </div>
            </div>
            <div class="mb-5 text-center text-lg-left">
              <!--
              <router-link id="ga-home-contact-us-button" class="btn btn-wide btn-primary transition-3d-hover" :to="{ path: '/contact' }">
                Contact Us
              </router-link>
              -->
              <a
                class="btn btn-primary mb-4 js-go-to transition-3d-hover"
                href="#javascript:;"
                data-hs-go-to-options="{
                  &quot;targetSelector&quot;: &quot;#benovelty-solution&quot;,
                  &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                  &quot;offsetTop&quot;: 0,
                  &quot;position&quot;: null,
                  &quot;animationIn&quot;: false,
                  &quot;animationOut&quot;: false
                  }"
              >Discover Our API & AI Solutions<i class="fas fa-arrow-down fa-sm ml-2" /></a>
            </div>
          </div>
        </div>
      </div>
      <!-- Hero Graphic (large Screen) -->
      <div class="d-none d-lg-flex flex-lg-wrap align-items-lg-end position-absolute top-0 left-50 space-top-3 mt-lg-6" style="width: 40rem;" data-aos="fade-left">
        <figure class="w-80 mx-auto">
          <img class="img-fluid" src="@/assets/svg/illustrations/home-hero.svg">
        </figure>
      </div>
      <!-- Hero Graphic (Medium and Small Screen) -->
      <div class="d-lg-none" data-aos="fade-up">
        <figure class="w-80 w-md-60 mx-auto">
          <img class="img-fluid" src="@/assets/svg/illustrations/home-hero.svg">
        </figure>
      </div>
      <!-- Featured Partners -->
      <div class="container space-2 mt-6 mt-lg-9">
        <div class="w-md-80 text-center mx-md-auto mb-5">
          <div class="text-center mb-4">
            <h2 class="divider divider-text">Trusted by Enterprises Across Industries</h2>
          </div>
        </div>
        <PartnerCarousel :items="partner_list" />
        <div>
          <div class="text-center mt-5">
            <!-- <a id="ga-home-business-story-hsbc-button" class="font-weight-bold" href="/case-study/hsbc"> -->
            <router-link id="ga-home-business-story-hsbc-button" class="font-weight-bold" :to="{ path: '/case-study/hsbc' }">
              Find Our Latest Business Story with HSBC<i class="fas fa-angle-right fa-sm ml-2" />
            </router-link>
            <!-- </a> -->
            <p class="mt-2 small">* Further references available upon request.</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Products & Solutions ========== -->
    <div class="container space-2 space-lg-3">
      <div class="w-md-85 w-lg-80 text-center mx-md-auto mb-5 mb-md-9">
        <span id="benovelty-solution" class="d-block small font-weight-bold text-cap mb-2">Our API &amp; Agentic AI Solutions</span>
        <h2 class="mb-4">Accelerate Business Growth with<br>Modular API and Agentic AI Solutions</h2>
        <p>beNovelty is an experienced, award-winning API solution provider for enterprises and startups. We have evolved from offering standalone API solutions to pioneering an approach that blends API and Agentic AI technologies.</p>
        <p>By harnessing the power of Agentic AI, we deliver highly effective, tailored solutions for complex challenges while avoiding the hallucination problems associated with Gen AI by using APIs as tools. Our modular approach empowers businesses to innovate swiftly, eliminating redundancies and enhancing efficiency. By seamlessly integrating API and AI, we provide scalable and intelligent digital interactions that drive transformative change.</p>
        <p>Join us on this journey to shape the future with beNovelty's AI-driven API ecosystem. Discover new opportunities and unlock your business's full potential!</p>
      </div>
      <div class="mx-auto text-center" data-aos="fade">
        <img class="img-fluid d-none d-lg-block" src="@/assets/svg/illustrations/bn-solutions-bg.svg">
        <img class="img-fluid d-lg-none" src="@/assets/svg/illustrations/bn-solutions-bg-sm.svg">
      </div>
      <!-- Navigation cards -->
      <div class="w-90 w-lg-75 text-center mx-auto mt-n4 mt-md-n7 mt-lg-n11">
        <div class="row">
          <!-- API-First Architecture card -->
          <div class="col-sm-6 mb-11 mb-sm-0 px-3 px-md-4 px-lg-5" data-aos="fade-up" data-aos-delay="250">
            <div class="card shadow-soft h-100 text-center" style="background-color: #f6fbff">
              <figure class="avatar-lg mx-auto floating mb-3">
                <img class="avatar-img" src="@/assets/svg/icons/icon-lego-api.svg">
              </figure>
              <div class="card-body pt-7">
                <h3>API-First Architecture</h3>
                <p class="font-size-1 mb-0">Efficient API solutions for seamless integration and superior performance.</p>
              </div>
              <div class="card-footer border-0 pt-0" style="background-color: #f6fbff">
                <a
                  class="btn btn-primary mb-4 js-go-to transition-3d-hover"
                  href="#javascript:;"
                  data-hs-go-to-options="{
                  &quot;targetSelector&quot;: &quot;#api-solution&quot;,
                  &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                  &quot;offsetTop&quot;: 0,
                  &quot;position&quot;: null,
                  &quot;animationIn&quot;: false,
                  &quot;animationOut&quot;: false
                  }"
                >Explore API Solutions<i class="fas fa-arrow-down fa-sm ml-2" /></a>
                <!--<router-link :to="{ path: '/solutions/api-first-architecture' }" class="font-size-1 font-weight-bold d-block">What is API-First Architecture?</router-link>-->
              </div>
            </div>
          </div>
          <!-- AI Agent Solution card-->
          <div class="col-sm-6 mb-0 mb-sm-0 px-3 px-md-4 px-lg-5" data-aos="fade-up" data-aos-delay="350">
            <div class="card shadow-soft h-100 text-center" style="background-color: #eaf6f8">
              <figure class="avatar-lg mx-auto floating mb-3">
                <img class="avatar-img" src="@/assets/svg/icons/icon-lego-ai.svg">
              </figure>
              <div class="card-body pt-7">
                <h3>Agentic AI Solutions</h3>
                <p class="font-size-1 mb-0">Intelligent AI agents for automating tasks and boosting operational efficiency.</p>
              </div>
              <div class="card-footer border-0 pt-0" style="background-color: #eaf6f8">
                <a
                  class="btn btn-primary mb-4 js-go-to transition-3d-hover"
                  href="#javascript:;"
                  data-hs-go-to-options="{
                  &quot;targetSelector&quot;: &quot;#ai-solution&quot;,
                  &quot;compensationSelector&quot;: &quot;#logoAndNav&quot;,
                  &quot;offsetTop&quot;: 0,
                  &quot;position&quot;: null,
                  &quot;animationIn&quot;: false,
                  &quot;animationOut&quot;: false
                  }"
                >Explore AI Solutions<i class="fas fa-arrow-down fa-sm ml-2" /></a>
                <!--<router-link :to="{ path: '/solutions/ai-agent' }" class="font-size-1 font-weight-bold d-block">What is Agentic AI?</router-link>-->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-90 w-lg-75 text-center mx-auto">
        <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-connector-1.svg">
      </div>

      <!-- API-First Architecture -->
      <div id="api-solution" class="card" style="background-color:#f6fbff" data-aos="fade">
        <div class="card-body p-lg-9">
          <!-- Intro -->
          <div class="row justify-content-md-between align-items-md-center mb-11">
            <div class="col-md-7 col-lg-8 mb-3 mb-md-0">
              <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-lego-api.svg">
              <h2>API-First Architecture</h2>
              <p>Seamlessly integrate and enhance performance with streamlined API solutions tailored for modern enterprises, ensuring efficient connectivity and scalability.</p>
            </div>
            <div class="col-md-5 col-lg-4">
              <div class="max-w-35rem mx-auto">
                <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-community.svg">
              </div>
            </div>
          </div>
          <!-- Enterprise -->
          <div class="card">
            <div class="h3"><span class="badge badge-soft-primary badge-pill floating p-3 px-4">beSpoke Services For Enterprises</span></div>
            <div class="card-body">
              <div class="text-center">
                <div class="mt-4 mb-6 special-heading">
                  <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
                  <h2 class="mb-0">Enterprise Services for API Platform Development</h2>
                  <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-3 mb-3 mb-md-5 mb-lg-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-6.svg">
                  </figure>
                  <h4>API Gateway and Infrastructure in Cloud Native Environment</h4>
                  <p>Secure, scalable API gateway and infrastructure solutions tailored for cloud-native environments.</p>
                </div>
                <div class="col-md-6 col-lg-3 mb-3 mb-md-5 mb-lg-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
                  </figure>
                  <h4 class="mb-3">API Services Development</h4>
                  <p>Custom API development to enhance connectivity and performance.</p>
                </div>
                <div class="col-md-6 col-lg-3 mb-3 mb-md-5 mb-lg-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-3.svg">
                  </figure>
                  <h4 class="mb-3">API Strategy and Ecosystem Development</h4>
                  <p>Strategic planning and ecosystem development to maximize API potential.</p>
                </div>
                <div class="col-md-6 col-lg-3 mb-3 mb-md-5 mb-lg-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-23.svg">
                  </figure>
                  <h4 class="mb-3">API Training & Hackathon</h4>
                  <p>Hands-on training sessions and hackathons to upskill your team and foster innovation.</p>
                </div>
              </div>
              <div class="text-center mt-3 mt-md-0 mt-lg-3 mb-5">
                <router-link class="btn btn-primary mr-1 mr-sm-3 mb-3 mb-md-0 transition-3d-hover" :to="{ path: '/contact' }">Talk to our API Consultant</router-link>
                <router-link :to="{ path: '/solutions/api-first-architecture' }" class="btn btn-outline-primary btn-white mr-1 mr-sm-0 transition-3d-hover">More About our Enterprise API Offering</router-link>
              </div>
            </div>
          </div>
          <!-- API-First SaaS Solutions -->
          <div class="text-center mx-md-auto mt-11 mb-5">
            <div class="h3"><span class="badge badge-soft-primary badge-pill p-3 px-4">Validate Your Idea Quickly and Avoid Managing Everything Yourself?</span></div>
            <div class="mt-4 mb-6 special-heading">
              <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
              <h2 class="mb-0">beNovelty's SaaS API Solutions</h2>
              <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixapi' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-fabrixapi-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>FabriXAPI Platform</h3>
                  <p><strong>FabriXAPI</strong> is a comprehensive API management platform enabling providers to build and share APIs effortlessly.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/openapihub' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-openapihub-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>OpenAPIHub</h3>
                  <p><strong>OpenAPIHub</strong> The leading API directory and community, connecting developers and fostering innovation.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-4 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fiberconnect' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-fiberconnect-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>FiberConnect</h3>
                  <p><strong>FiberConnect</strong> is ready-made API gateway solutions for the financial services sector, connecting banks and insurance companies with ease.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="w-90 w-lg-75 text-center mx-auto">
        <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-connector-2.svg">
      </div>

      <!-- AI Agent Solution -->
      <div id="ai-solution" class="card" style="background-color:#eaf6f8" data-aos="fade">
        <div class="card-body p-lg-9">
          <!-- Intro -->
          <div class="row justify-content-md-between align-items-md-center mb-11">
            <div class="col-md-7 col-lg-8 mb-3 mb-md-0">
              <img class="img-fluid max-w-8rem mb-3" src="@/assets/svg/icons/icon-lego-ai.svg">
              <h2>Agentic AI Solutions</h2>
              <p>Harness intelligent AI agents to automate tasks and boost overall operational efficiency, tailored to meet your unique business needs.</p>
            </div>
            <div class="col-md-5 col-lg-4">
              <div class="max-w-35rem mx-auto">
                <img class="img-fluid" src="@/assets/svg/illustrations/ai-agent-2.svg">
              </div>
            </div>
          </div>
          <!-- Enterprise -->
          <div class="card">
            <div class="h3"><span class="badge badge-soft-success badge-pill floating p-3 px-4">beSpoke Services For Enterprises</span></div>
            <div class="card-body">
              <div class="text-center">
                <div class="mt-4 mb-6 special-heading green">
                  <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
                  <h2 class="mb-0">Enterprise Services for AI Agent Development</h2>
                  <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-3 mb-md-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-6.svg">
                  </figure>
                  <h4>AI Gateway and Infrastructure in Cloud Native Environment</h4>
                  <p>Advanced AI gateway and infrastructure designed for optimal performance in cloud-native settings.</p>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-44.svg">
                  </figure>
                  <h4 class="mb-3">AI Agents Development</h4>
                  <p>Custom development of AI agents to meet specific business needs.</p>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                  <figure class="max-w-6rem mb-2">
                    <img class="img-fluid" src="@/assets/svg/icons/icon-3.svg">
                  </figure>
                  <h4 class="mb-3">RAG, LLM Fine Tuning and Guardrails</h4>
                  <p>Specialized services in Retrieval-Augmented Generation (RAG), large language model fine-tuning, and implementation of guardrails for AI safety.</p>
                </div>
              </div>
              <div class="text-center mt-3 mt-md-0 mt-lg-3 mb-5">
                <router-link class="btn btn-primary mr-1 mr-sm-3 mb-3 mb-md-0 transition-3d-hover" :to="{ path: '/contact' }">Talk to our AI Consultant</router-link>
                <router-link :to="{ path: '/solutions/ai-agent' }" class="btn btn-outline-primary btn-white mr-1 mr-sm-0 transition-3d-hover">More About our Enterprise AI Offering</router-link>
              </div>
            </div>
          </div>
          <!-- AI SaaS Solutions -->
          <div class="text-center mx-md-auto mt-11 mb-5">
            <div class="h3"><span class="badge badge-soft-success badge-pill p-3 px-4">Validate Your Idea Quickly and Avoid Managing Everything Yourself?
            </span></div>
            <div class="mt-4 mb-6 special-heading green">
              <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
              <h2 class="mb-0">beNovelty's SaaS Agentic AI Solutions</h2>
              <img src="@/assets/svg/icons/star.svg" class="img-fluid" style="width:16px">
            </div>
          </div>
          <div class="row w-lg-75 mx-auto">
            <div class="col-12 col-md-6 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/fabrixai' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-fabrixai-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>FabriXAI</h3>
                  <p><strong>FabriXAI</strong> is an AI agent creation and workflow platform for both tech and non-tech users.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
            <div class="col-12 col-md-6 mb-4">
              <router-link class="card h-100 transition-3d-hover" :to="{ path: '/products/taptab' }">
                <div class="card-img-top position-relative">
                  <img class="card-img-top" src="@/assets/img/products/products-taptab-thumbnail.jpg">
                </div>
                <div class="card-body">
                  <h3>TapTab AI</h3>
                  <p><strong>TapTab AI</strong> is an award-winning specialized AI assistant for the education sector.</p>
                </div>
                <div class="card-footer border-0 pt-0">
                  <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- CTA Section -->
      <div class="container space-top-3">
        <div class="text-center mx-md-auto">
          <div class="card bg-primary text-white overflow-hidden p-4">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left">
              <div class="col-md-6 offset-md-3 mb-3 mb-md-0">
                <h2 class="text-white mb-1">Got a Question?</h2>
                <p class="text-white mb-0">We'd love to talk about how we can help you.</p>
              </div>
              <div class="col-md-3 text-md-right">
                <router-link class="btn btn-light transition-3d-hover btn-wide" :to="{ path: '/contact' }">Contact Us</router-link>
              </div>
            </div>

            <!-- SVG Component -->
            <figure class="w-25 d-none d-md-block content-centered-y ml-n4">
              <img class="img-fluid" src="@/assets/svg/illustrations/communication.svg" alt="Image Description">
            </figure>
            <!-- End SVG Component -->
          </div>
        </div>
      </div>

    </div>

    <!-- ========== End Products & Solutions ========== -->

    <!-- ========== Partners Section ========== -->
    <div class="position-relative bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
      <div class="container space-2 space-lg-3 position-relative z-index-2">
        <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2"> Trust at beNovelty</span>
          <h2>Your Security is Our Top Priority</h2>
        </div>
        <div class="row justify-content-lg-between align-items-md-center">
          <div class="col-md-6 mb-11 mb-md-0">
            <p>beNovelty is trusted by the fastest growing startups and the largest enterprises. Our APIs and platform adhere to leading security and compliance standards to ensure that all your communication and data are encrypted both in rest and motion.</p>
            <div class="mt-5">
              <!-- <a id="ga-home-trust-button" class="font-weight-bold" href="/trust"> -->
              <router-link id="ga-home-trust-button" class="font-weight-bold" :to="{ path: '/trust' }">
                Visit our Trust Center <i class="fas fa-angle-right fa-sm ml-2" />
              </router-link>
              <!-- </a> -->
            </div>
          </div>

          <div class="col-md-6" data-aos="fade-up">
            <img class="img-fluid" src="@/assets/img/certificates/cert-graphic.png">
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Partners Section ========== -->

    <!-- ========== Testimonials ========== -->
    <div class="container space-2 space-lg-top-3" data-aos="fade-up">
      <div class="position-relative bg-light rounded shadow space-2" :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-8.svg') + ')'}">
        <div class="w-md-80 w-lg-80 text-center mx-auto mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">testimonials</span>
          <h2>What Our Partners Say</h2>
        </div>
        <PartnerSayCarousel :items="testimonial_list" />
      </div>
    </div>
    <!-- ========== End Testimonials ========== -->

    <!-- ===== CTA Section ===== -->
    <div class="text-center bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
      <div class="container space-2">
        <div class="mb-6">
          <h2>Are You Ready to Innovate Without Redundancies?</h2>
          <p>Discover how beNovelty can help you gain an Experience Edge for your business.</p>
        </div>
        <router-link id="ga-home-contact-us-button" class="btn btn-wide btn-primary transition-3d-hover" :to="{ path: '/contact' }">
          Let's Talk
        </router-link>
      </div>
    </div>
    <!-- ===== End CTA Section ===== -->

    <!-- ========== Subscription Section ========== -->
    <!-- <div class="container-fluid space-2 space-lg-3">
      <div class="container">
        <div class="text-center cta-bg" data-aos="fade-up">
          <h2>Be the First to Know</h2>
          <p>Subscribe to our e-newsletter to be the first to receive our latest news, promotions and events.</p>
          <div class="w-md-90 w-lg-65 mx-auto form-subscribe mt-5">
            <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=00a108e7f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from beNovelty or OpenAPIHub by beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2142806,2518966"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Submit" name="subscribe" class="btn btn-primary border-0 mx-auto"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <!-- Hubspot Form - Provider Modal -->
    <!-- <div id="providerForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="providerFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="providerFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <form ref="providerForm" />
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Hubspot Form - Consumer Modal -->
    <!-- <div id="consumerForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="consumerFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="consumerFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <form ref="consumerForm" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
// import PracticesCard from '@/components/practices-card'
import PartnerCarousel from '@/components/partner-carousel'
import PartnerSayCarousel from '@/components/partner-say-carousel'
import HSGoTo from '../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../assets/js/hs.core.js'
import '../../assets/js/hs.slick-carousel.js'
import '../../assets/vendor/slick-carousel/slick/slick.js'
import HSStickyBlock from '@/assets/vendor/hs-sticky-block/src/js/hs-sticky-block'
import { mapGetters } from 'vuex'
import 'bootstrap'
export default {
  name: 'Index',
  components: {
    // PracticesCard,
    PartnerCarousel,
    PartnerSayCarousel
  },
  data() {
    return {
      block_item_list: [
        {
          data_aos_delay: 0,
          url: 'solutions/api-consultancy',
          title: 'API Consultancy',
          image_url: 'icon-3.svg',
          content: 'High quality enterprise API consultancy & training services for API strategy enablement.'
        },
        {
          data_aos_delay: 150,
          url: 'solutions/api-product',
          title: 'API-as-a-Product',
          image_url: 'icon-6.svg',
          content: 'Bussiness API-as-a-Product prototyping & development'
        },
        {
          data_aos_delay: 200,
          url: 'solutions/api-management',
          title: 'API Management',
          image_url: 'icon-2.svg',
          content: 'API Management Platform for companies to unlock API business integration with lower budgets and shorter timeline.'
        },
        {
          data_aos_delay: 250,
          url: 'solutions/api-community',
          title: 'API Community',
          image_url: 'icon-4.svg',
          content: 'API business enabler & community builder for local API Community. Local organizer of Apidays Hong Kong.'
        }
      ],
      highlight_list: [
        {
          title: 'An Award-Winning API First Company',
          image_url: 'icon-27.svg',
          class: 'col-md-4 mb-5 mb-md-0'
        },
        {
          title: 'Strong Track Records in Enterprise Market',
          image_url: 'icon-7.svg',
          class: 'col-md-4 mb-5 mb-md-0'
        },
        {
          title: 'Leading Local API Community',
          image_url: 'icon-25.svg',
          class: 'col-md-4'
        }
      ],
      // logo_list: [
      //   [
      //     {
      //       class: 'avatar-xl mt-n3 ml-0 ml-lg-5',
      //       data_aos_delay: 0,
      //       image_url: 'belun-square.png'
      //     },
      //     {
      //       class: 'avatar-xl mx-auto mt-n5',
      //       data_aos_delay: 50,
      //       image_url: 'qfpay-logo.jpg'
      //     },
      //     {
      //       class: 'avatar-xxl ml-n2 ml-sm-0',
      //       data_aos_delay: 150,
      //       image_url: 'hkqaa.png'
      //     }
      //   ],
      //   [
      //     {
      //       class: 'avatar-xl ml-auto',
      //       data_aos_delay: 200,
      //       image_url: 'telkie.jpg'
      //     },
      //     {
      //       class: 'avatar-xl ml-0 ml-sm-5 mt-5',
      //       data_aos_delay: 250,
      //       image_url: 'taptab.jpg'
      //     },
      //     {
      //       class: 'avatar-xxl ml-0 ml-sm-5',
      //       data_aos_delay: 250,
      //       image_url: 'hkstp.jpg'
      //     }
      //   ],
      //   [
      //     {
      //       class: 'avatar-xxl ml-auto',
      //       data_aos_delay: 300,
      //       image_url: 'sunstar.png'
      //     },
      //     {
      //       class: 'avatar-xl ml-auto',
      //       data_aos_delay: 350,
      //       image_url: 'jebsen.png'
      //     }
      //   ]
      // ],
      // oah_form_list: [
      //   {
      //     title: 'API Providers',
      //     image_url: 'provider-apply.svg',
      //     form: '#providerForm'
      //   },
      //   {
      //     title: 'API Consumers',
      //     image_url: 'developer-apply.svg',
      //     form: '#consumerForm'
      //   },
      //   {
      //     title: 'Community Partners',
      //     image_url: 'community-apply.svg',
      //     form: '#providerForm'
      //   }
      // ],
      // practice_list: [],
      partner_list: [],
      testimonial_list: []
    }
  },
  computed: {
    ...mapGetters([
      'partners'
      // 'practices'
    ])
  },
  created() {
    this.partner_list = this.$store.getters.partners.partner_list
    // this.practice_list = this.$store.getters.practices.practice_list
    this.testimonial_list = this.$store.getters.partners.testimonial_list
  },
  mounted() {
    $('.js-sticky-block').each(function() {
      new HSStickyBlock($(this)).init()
    })
    $('.js-slick-carousel').not('.slick-initialized').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    // this.loadScriptConsumerForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "e6964e44-3c2d-42e7-9ac0-07325ba0d311"
    //     });
    //   `
    //   this.$refs.consumerForm.appendChild(secondScript)
    // })
    // this.loadScriptProviderForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "f09151ed-974f-40a3-a96a-8ff19e1754f3"
    //     });
    //   `
    //   this.$refs.providerForm.appendChild(secondScript)
    // })
    // this.loadScriptSubscriptionForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.subscriptionForm.appendChild(secondScript)
    // })
  },
  // methods: {
  //   loadScriptConsumerForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.consumerForm.appendChild(script)
  //   },
  //   loadScriptProviderForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.providerForm.appendChild(script)
  //   },
  //   loadScriptSubscriptionForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.subscriptionForm.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: '%s | Leading API and Agentic AI Solutions for Enterprises and Startups',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'beNovelty | Leading API and Agentic AI Solutions for Enterprises and Startups' },
        { property: 'og:description', content: 'Transform your business with beNovelty\'s award-winning API and Agentic AI solutions. Our innovative, scalable, and efficient digital solutions empower growth and streamline operations for enterprises and startups.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");

.floating {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.special-heading {
  grid-column-gap: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.special-heading h2 {
  background-image: linear-gradient(to right, #57abf0, #007dba);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.green h2 {
  background-image: linear-gradient(to right, #3eb589, #007dba);
}
</style>
